import React from 'react';
import Layout from 'components/layout';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import linkResolver from 'helpers/linkResolver';
import {
  withPrismicUnpublishedPreview,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews';

import Page from 'templates/page';

function NotFoundPage() {
  return (
    <Layout headerOpacity={1}>
      <Box pt={15}>
        <Typography component="p" variant="h2" align="center">
          Sorry, that page doesnt exist!
        </Typography>
      </Box>
    </Layout>
  );
}

export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
    linkResolver,
    componentResolver: componentResolverFromMap({
      page: Page,
    }),
  },
]);
